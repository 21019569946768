import { TranslateFunction } from 'react-utilities';
import { Loading } from 'react-style-guide';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TExploreApiGameSort } from '../../common/types/bedev2Types';
import { EventStreamMetadata, SessionInfoType } from '../../common/constants/eventStreamConstants';
import { PageContext } from '../../common/types/pageContext';
import bedev2Services from '../../common/services/bedev2Services';
import {
  getSortAppliedFiltersMetadata,
  getSortTargetIdMetadata,
  mapExploreApiGameSortResponse
} from '../../omniFeed/utils/gameSortUtils';
import GameGrid from '../../common/components/GameGrid';
import useGameImpressionsIntersectionTracker, {
  TBuildGridGameImpressionsEventProperties
} from '../../common/hooks/useGameImpressionsIntersectionTracker';
import { TBuildEventProperties } from '../../common/components/GameTileUtils';
import ErrorStatus from '../../common/components/ErrorStatus';
import { CommonGameSorts } from '../../common/constants/translationConstants';
import { getSponsoredAdImpressionsData } from '../../common/utils/parsingUtils';
import { TGameData } from '../../common/types/bedev1Types';
import useVerticalScrollTracker from '../../common/components/useVerticalScrollTracker';
import { usePageSession } from '../../common/utils/PageSessionContext';
import GamesInfoTooltip from '../../common/components/GamesInfoTooltip';
import { gamesPage } from '../../common/constants/configConstants';
import IconResolutionExperimentContextProvider from '../../common/utils/IconResolutionExperimentContextProvider';
import getDeviceFeatures from '../../common/utils/deviceFeaturesUtils';
import useFilterUrlParams from '../../omniFeed/hooks/useFilterUrlParams';

const GamesSeeAllGrid = ({
  sortName,
  translate
}: {
  sortName: string;
  translate: TranslateFunction;
}): JSX.Element => {
  const gridRef = useRef<HTMLDivElement>(null);
  const tileRef = useRef<HTMLDivElement>(null);

  const [sortData, setSortData] = useState<TExploreApiGameSort | undefined>(undefined);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [error, setError] = useState<boolean>(false);

  const discoverPageSessionInfo = usePageSession();

  const { filters, getInitialUrlParamFilters } = useFilterUrlParams();

  const deviceFeatures = useMemo(() => {
    return getDeviceFeatures();
  }, []);

  const fetchData = useCallback(
    (filterParams: Map<string, string>, pageToken?: string) => {
      setError(false);
      setIsFetching(true);
      bedev2Services
        .getExploreSortContents(
          discoverPageSessionInfo,
          sortName,
          pageToken,
          filterParams,
          deviceFeatures
        )
        .then(data => mapExploreApiGameSortResponse(data))
        .then(sort => {
          if (!pageToken) {
            setSortData(sort);
          } else {
            setSortData(prevSortData => {
              if (prevSortData) {
                return {
                  ...prevSortData,
                  games: [...prevSortData.games, ...sort.games],
                  nextPageToken: sort.nextPageToken
                };
              }
              return prevSortData;
            });
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    [sortName, discoverPageSessionInfo, deviceFeatures]
  );

  const checkLoadMoreData = useCallback(() => {
    if (sortData?.nextPageToken && !isFetching) {
      fetchData(filters, sortData?.nextPageToken);
    }
  }, [fetchData, isFetching, sortData?.nextPageToken, filters]);

  useEffect(() => {
    fetchData(getInitialUrlParamFilters());
  }, [fetchData, getInitialUrlParamFilters]);

  useVerticalScrollTracker(PageContext.SortDetailPageDiscover);

  const buildEventProperties: TBuildEventProperties = (data: TGameData, id: number) => ({
    [EventStreamMetadata.PlaceId]: data.placeId,
    [EventStreamMetadata.UniverseId]: data.universeId,
    [EventStreamMetadata.IsAd]: data.isSponsored,
    [EventStreamMetadata.NativeAdData]: data.nativeAdData,
    [EventStreamMetadata.Position]: id,
    [EventStreamMetadata.NumberOfLoadedTiles]: (sortData?.games || []).length,
    [EventStreamMetadata.GameSetTypeId]: sortData?.topicId,
    ...getSortTargetIdMetadata(sortData),
    ...getSortAppliedFiltersMetadata(sortData),
    [EventStreamMetadata.Page]: PageContext.SortDetailPageDiscover,
    [SessionInfoType.DiscoverPageSessionInfo]: discoverPageSessionInfo
  });

  const buildGameImpressionsProperties: TBuildGridGameImpressionsEventProperties = useCallback(
    (viewedIndex: number[]) => {
      if (sortData?.games !== undefined) {
        const parsedViewedIndex = viewedIndex.filter(id => id < sortData.games.length);
        return {
          [EventStreamMetadata.RootPlaceIds]: parsedViewedIndex.map(
            id => sortData.games[id].placeId
          ),
          [EventStreamMetadata.UniverseIds]: parsedViewedIndex.map(
            id => sortData.games[id].universeId
          ),
          [EventStreamMetadata.AbsPositions]: parsedViewedIndex,
          ...getSponsoredAdImpressionsData(sortData.games, parsedViewedIndex),
          [EventStreamMetadata.EmphasisFlag]: false,
          [EventStreamMetadata.GameSetTypeId]: sortData?.topicId,
          ...getSortTargetIdMetadata(sortData),
          ...getSortAppliedFiltersMetadata(sortData),
          [EventStreamMetadata.Page]: PageContext.SortDetailPageDiscover,
          [SessionInfoType.DiscoverPageSessionInfo]: discoverPageSessionInfo
        };
      }

      return undefined;
    },
    [sortData, discoverPageSessionInfo]
  );

  useGameImpressionsIntersectionTracker(
    gridRef,
    sortData?.games?.length ?? 0,
    buildGameImpressionsProperties
  );

  if (error && !sortData) {
    return (
      <div className='game-sort-detail-container'>
        <h1>{translate(CommonGameSorts.LabelGames)}</h1>
        <div className='game-sort-detail'>
          <ErrorStatus
            errorMessage={translate(CommonGameSorts.LabelApiError)}
            onRefresh={() => fetchData(filters)}
          />
        </div>
      </div>
    );
  }

  if (sortData === undefined) {
    return <Loading />;
  }

  return (
    <IconResolutionExperimentContextProvider>
      <div className='game-sort-detail-container'>
        <h1>
          {sortData.topic}
          {sortData.topicId === gamesPage.adSortDiscoverId && (
            <GamesInfoTooltip
              tooltipText={
                translate(CommonGameSorts.LabelSponsoredAdsDisclosureStatic) ||
                'Sponsored experiences are paid for by Creators. They may be shown to you based on general information about your device type, location, and demographics.'
              }
              sizeInPx={22}
            />
          )}
        </h1>
        <GameGrid
          ref={gridRef}
          emphasis={false}
          tileRef={tileRef}
          gameData={sortData.games}
          translate={translate}
          buildEventProperties={buildEventProperties}
          shouldUseSentinelTile={!error}
          loadData={checkLoadMoreData}
        />
        {isFetching && <Loading />}
      </div>
    </IconResolutionExperimentContextProvider>
  );
};

export default GamesSeeAllGrid;
