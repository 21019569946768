export const DEFAULT_TARGET_TYPES = {
  DEFAULT: 0,
  WWW: 1,
  STUDIO: 2,
  DIAGNOSTIC: 3
};

export const EVENT_TYPES = {
  formInteraction: 'formInteraction',
  modalAction: 'modalAction',
  pageLoad: 'pageLoad',
  buttonClick: 'buttonClick'
};
