import { ready } from 'core-utilities';
import React from 'react';
import { render } from 'react-dom';
import App from './App';

import '../../../css/gameCarousel/gameCarousel.scss';

const gamesCarouselPageId = 'games-carousel-page';

ready(() => {
  render(<App />, document.getElementById(gamesCarouselPageId));
});
