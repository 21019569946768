import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import { withTranslations } from 'react-utilities';
import { translationConfig } from './translation.config';
import GamesOmniFeed from '../../../ts/react/gamesPage/GamesOmniFeed';
import SortDetailExploreApi from '../../../ts/react/sortDetail/exploreApi/SortDetailExploreApi';
import SortDetailV2 from '../../../ts/react/sortDetail/SortDetailV2';

function App(props) {
  return (
    <HashRouter>
      <Route exact path='/' render={() => <GamesOmniFeed {...props} />} />
      <Route exact path='/sortName/:sortName' component={SortDetailExploreApi} />
      <Route exact path='/sortName/v2/:sortName' component={SortDetailV2} />
    </HashRouter>
  );
}

export default withTranslations(App, translationConfig);
